.contentBlocks {
  padding-top: var(--space-9);
  padding-bottom: var(--space-9);
}

@media (--breakpointLg) {
  .contentBlocks {
    padding-top: 128px;
    padding-bottom: 128px;
  }
}

.textLowercase {
  text-transform: lowercase;
}
