.tabContent {
  box-shadow: inset 0 7px 9px -7px rgba(0, 0, 0, 0.14);
}

.tabContentImage {
  width: 100%;
  height: auto;
}

.tabDescription {
  max-width: 700px;
}

.tabContentHeader {
  text-align: center;
}

@media (--breakpointMd) {
  .tabContentSection {
    max-width: 375px;
  }

  .tabContentHeader {
    text-align: left;
  }

  .tabList {
    max-width: 540px;
  }
}
