.multiThumbnailsRow {
  display: flex;
  flex-direction: column;
  padding-top: 48px;
}

@media (--breakpointMd) {
  .multiThumbnailsRow {
    flex-direction: row;
    margin: 0 5vw;
  }
}
