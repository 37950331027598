.starsImage {
  padding: 10% 10% 5% 10%;
  width: 20%;
  background-size: cover;
}

.leftStars {
  align-self: flex-start;
  background-image: url('../images/left-2stars.svg');
}

.rightStars {
  align-self: flex-end;
  background-image: url('../images/right-2stars.svg');
}

@media (--breakpointMd) {
  .starsImage {
    align-self: center;
    padding: 18% 10%;
    width: auto;
  }

  .leftStars {
    background-image: url('../images/left-4stars.svg');
  }

  .rightStars {
    background-image: url('../images/right-4stars.svg');
  }
}
