.banner {
  height: 50px;
  width: 100%;
  position: sticky;
  top: 0;
  /* make sure layered above floatLabels which have z-index of 2 */
  z-index: 3;
  box-shadow:
    0 4px 4px 0 rgba(0, 0, 0, 0.12),
    0 0 2px 0 rgba(0, 0, 0, 0.24);
}
