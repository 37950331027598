:root {
  --image-width: 112px;
  --image-right-padding: 16px;
  --image-max-width: calc(var(--image-width) + var(--image-right-padding));
}

.cardContainer {
  display: flex;
  flex: 1;
  max-width: calc(100vw - 32px);
  padding-bottom: 32px;
}

.image {
  max-width: var(--image-max-width);
  margin: 0 auto;
}

.subHeading {
  padding: 12px 0;
}

.cardContent {
  min-width: calc(100% - var(--image-max-width));
}

@media (--breakpointMd) {
  .cardContainer {
    flex-direction: column;
    margin: 0 24px;
    max-width: 391px;
    padding-bottom: 0;
  }
}
