.outerBox {
  background-color: var(--colorGreenLight);
}

.innerBox {
  background-color: var(--colorGreenLight);
}

.contentColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.programContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 32px 3px;
}

.contentContainer {
  display: flex;
  flex-direction: row;
  margin: 20px 2px;
  align-items: center;
}

.imageContainer {
  min-width: 112px;
}

.offset {
  /* Keeps the banner from going under the sticky subnav */
  scroll-margin-top: 51px;
}

@media (--breakpointSm) {
  .programContainer {
    flex-direction: column;
    padding: 32px 5px;
  }
}

@media (--breakpointMd) {
  .outerBox {
    background-color: white;
    background-image: url('./images/loyalty-partnerships-background.png');
    background-size: cover;
  }

  .contentColumn {
    flex-direction: row;
  }

  .programContainer {
    flex-direction: row;
    padding: 32px 3px;
  }

  .contentContainer {
    flex-direction: column;
  }
}

@media (--breakpointLg) {
  .programContainer {
    padding: 32px 5px;
  }
}

@media (--breakpointXl) {
  .programContainer {
    padding: 32px 20px;
  }
}

@media (--breakpointXxl) {
  .programContainer {
    padding: 32px 50px;
  }
}
