.target:target {
  /* flashes a background color when this element is in the url hash */
  animation: flash-bg 2s;
  scroll-margin-top: 100px;
}

@keyframes flash-bg {
  0% {
    background: hsl(var(--colorYellowHsl) / 0%);
  }
  50% {
    background: hsl(var(--colorYellowHsl) / 80%);
  }
  100% {
    background: hsl(var(--colorYellowHsl) / 0%);
  }
}
