@import '@starbucks-web/pattern-library/lib/styles/vars.css';
@import '../../../../../shared/app/vars.cssm';

.bgWhiteWarm {
  background-color: var(--colorWhiteWarm);
}

.colorGreenSlate {
  color: var(--colorGreenSlate);
}

.letterSpacingNormal {
  letter-spacing: var(--letterSpacingNormal) !important;
}
